import React from 'react';
import { IssueFormWrap } from 'react-component-utils';
import { CredentialPayload, VerifiableCredential } from '@vckit/core-types';

import { AgtraceLinkResponse } from '../models/gs1';
import {
    JsonFormData,
    defaultIssueFormValue,
    uploadJson,
    BucketName,

    // models
    AgtraceLinkResolver,
    GS1LinkResponse,
    IdentificationKeyType,
    LinkType,
    MimeType,

    // services
    createLinkResolver,

    // utils
    integrateVckitIssueVC,
    generateUUID
} from 'react-component-utils';
import { getLinkResolver } from '../services/linkResolver.service';
import { credentialsIssue } from '../schemas';
import eNVD from '../constants/credentials/eNVD.json';

const formName = 'DigitalLivestockConsignment';
const title = 'Digital Livestock Consignment';
/**
 * DigitalLivestockConsignment component is used to display the schema of DigitalLivestockConsignment.
 */
export const DigitalLivestockConsignment = () => {
    const [dynamicSchema, setDynamicSchema] = React.useState<any>(null);

    React.useEffect(() => {
        const schema: any = credentialsIssue[formName].schema;
        setDynamicSchema(schema);
    }, []);

    const processor = async (formData: JsonFormData, credentialPayload: CredentialPayload) => {
        try {
            const vc = await issueCredential(formData, credentialPayload);
            const consignmentNumber = eNVD.consignmentNumber;
            const vcUrl = await uploadVC(`${consignmentNumber}/${generateUUID()}`, vc);
            await registerLinkResolver(vcUrl, IdentificationKeyType.consignment_id, consignmentNumber, title);
            return { vc };
        } catch (error: any) {
            return { errorMessage: error.message };
        }
    };

    /**
     * issue credential
     */
    const issueCredential = async (formData: JsonFormData, credentialPayload: CredentialPayload) => {
        try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { schema, uischema, description, context, data, ...restOfVC } = credentialsIssue[formName];

            const result: VerifiableCredential = await integrateVckitIssueVC({
                context,
                credentialSubject: formData,
                ...credentialPayload,
                restOfVC,
            });

            const credentialValue = result;
            return credentialValue;
        } catch (error) {
            throw new Error('Error issue credential');
        }
    };

    /**
     * upload VC to S3 after issue credential success and get the passport link
     */
    const uploadVC = async (filename: string, vc: VerifiableCredential) => {
        const result = await uploadJson(filename, BucketName.PublicVC, vc);
        return result;
    };

    /**
     * register link resolver to GS1 after getting from uploadVC
     */
    const registerLinkResolver = async (
        url: string,
        identificationKeyType: IdentificationKeyType,
        identificationKey: string,
        passportTitle: string,
    ) => {
        const linkResolver: AgtraceLinkResolver = {
            identificationKeyType,
            identificationKey: identificationKey,
            itemDescription: `${defaultIssueFormValue[formName].title}`,
        };
        let epcisResponses: GS1LinkResponse[] = [];
        let epcisQualifierPath = '';

        try {
            const linkResolvers = await getLinkResolver(identificationKeyType, identificationKey);
            if (linkResolvers && linkResolvers.length > 0) {
                const lastActiveLinkResolver = linkResolvers.reverse().find((linkResolver) => linkResolver.active);
                if (lastActiveLinkResolver) {
                    epcisResponses = lastActiveLinkResolver.responses.filter(
                        (response: any) => response.linkType === LinkType.epcisLinkType && response.active,
                    );
                    if (epcisResponses.length > 0) {
                        epcisQualifierPath = lastActiveLinkResolver.qualifierPath;
                    }
                }
            }
        } catch (error) {
            console.log('error', error);
        }

        const query = encodeURIComponent(JSON.stringify({ payload: { uri: url } }));
        const queryString = `/?q=${query}`;
        const verificationPassportPage = `${process.env.REACT_APP_VERIFICATION_PAGE!}${queryString}`;
        let qualifierPath = queryString;

        const linkResponses: AgtraceLinkResponse[] = [
            {
                linkType: LinkType.verificationLinkType,
                linkTitle: 'VCKit verify service',
                targetUrl: (process.env.REACT_APP_VERIFICATION_TARGET_URL as string) || '',
                mimeType: MimeType.textPlain,
            },
            {
                linkType: LinkType.certificationLinkType,
                linkTitle: passportTitle,
                targetUrl: url,
                mimeType: MimeType.applicationJson,
            },
        ];

        if (epcisQualifierPath && epcisResponses.length > 0) {
            qualifierPath = epcisQualifierPath;

            linkResponses.push(...epcisResponses);
        } else {
            linkResponses.push({
                linkType: LinkType.certificationLinkType,
                linkTitle: passportTitle,
                targetUrl: verificationPassportPage,
                mimeType: MimeType.textHtml,
                defaultLinkType: true,
                defaultIanaLanguage: true,
                defaultMimeType: true,
            });
        }

        await createLinkResolver(linkResolver, linkResponses, qualifierPath);
    };

    return (
        <>
            {dynamicSchema && <IssueFormWrap formName={formName} processor={processor} dynamicSchema={dynamicSchema} />}
        </>
    );
};
