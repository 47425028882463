import React from 'react';
import { CredentialPayload, VerifiableCredential } from '@vckit/core-types';
import {
    IssueFormWrap,
    EPCISEventAction,
    EPCISEventDisposition,
    EPCISEventType,
    JsonFormData,
    defaultIssueFormValue,

    // models
    AgtraceLinkResolver,
    IdentificationKeyType,
    LinkType,
    MimeType,

    // services
    uploadJson,
    BucketName,
    integrateVckitIssueVC,
    createLinkResolver,
    encryptedService,

    // utils
    generateUUID,
    splitStringByDash,
} from 'react-component-utils';

import { credentialsIssue } from '../schemas';
import eNVD from '../constants/credentials/eNVD.json';
import { epcisTransactionCrendentialRender } from '../models/epcis';
import { AgtraceLinkResponse } from '../models/gs1';

const formName = 'NationalVendorDeclaration';

/**
 * NationalVendorDeclaration component is used to display the schema of NationalVendorDeclaration.
 */
export const NationalVendorDeclaration = () => {
    const [dynamicSchema, setDynamicSchema] = React.useState<any>(null);

    React.useEffect(() => {
        if (!dynamicSchema) {
            const schema: any = credentialsIssue[formName].schema;

            if (schema?.properties?.sender?.enum)
                schema.properties.sender.enum = [`${eNVD.origin.name}-${eNVD.origin.pic}`];

            if (schema?.properties?.receiver?.enum)
                schema.properties.receiver.enum = [`${eNVD.destination.name}-${eNVD.destination.pic}`];

            setDynamicSchema(schema);
        }
    }, []);

    const processor = async (formData: JsonFormData, credentialPayload: CredentialPayload) => {
        try {
            const vc = await issueEnvdVC(formData, credentialPayload);
            const eNVDVCUrl = await encryptedVC(`${eNVD.consignmentNumber}/${generateUUID()}`, vc);
            const addQueryParamsENVDvcUrl = JSON.stringify({ uri: eNVDVCUrl.uri, hash: eNVDVCUrl.hash });
            const EPCISTransactionEventVC =
                eNVDVCUrl && (await issueEPCISTransactionEventVC(formData, credentialPayload, addQueryParamsENVDvcUrl));

            const EPCISVCUrl =
                EPCISTransactionEventVC &&
                (await uploadVC(`${eNVD.consignmentNumber}/${generateUUID()}`, EPCISTransactionEventVC));
            await registerLinkResolver(
                EPCISVCUrl,
                IdentificationKeyType.consignment_id,
                eNVD.consignmentNumber,
                'EPCIS transaction event VC',
            );

            return { vc };
        } catch (error: any) {
            return { errorMessage: error.message };
        }
    };

    /**
     * issue credential for eNVD
     */
    const issueEnvdVC = async (formData: JsonFormData, credentialPayload: CredentialPayload) => {
        try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { schema, uischema, description, context, data, ...restOfVC } = credentialsIssue[formName];

            const credentialSubject = updateCredentialSubjectForENVD({ ...eNVD }, formData);

            const result: VerifiableCredential = await integrateVckitIssueVC({
                context,
                credentialSubject,
                ...credentialPayload,
                restOfVC,
            });

            const credentialValue = result;
            return credentialValue;
        } catch (error) {
            throw new Error('Error issue credential');
        }
    };

    const updateCredentialSubjectForENVD = (credentialSubject: any, formData: JsonFormData) => {
        const answersOf10Indexes = (credentialSubject.answers as any[]).reduce(
            (acc: number[], answer: any, idx: number) => {
                if (answer && answer.questionId === '10') {
                    acc.push(idx);
                }
                return acc;
            },
            [],
        );

        (credentialSubject.answers as any[]).splice(
            answersOf10Indexes[0],
            answersOf10Indexes.length,
            ...formData['livestockIds'].map((livestockId: string, idx: number) => ({
                questionId: '10',
                value: livestockId,
                index: idx,
            })),
        );

        const now = new Date().toISOString();

        const senderData = splitStringByDash(formData['sender']);
        const receiverData = splitStringByDash(formData['receiver']);
        credentialSubject.origin = { name: senderData[0], pic: senderData[1] };
        credentialSubject.destination = { name: receiverData[0], pic: receiverData[1] };
        credentialSubject.createdAt = now;
        credentialSubject.updatedAt = now;
        credentialSubject.submittedAt = now;
        return credentialSubject;
    };

    /**
     * issue credential for EPCIS Transaction Event
     */
    const issueEPCISTransactionEventVC = async (
        formData: JsonFormData,
        credentialPayload: CredentialPayload,
        eNVDVCUrl: string,
    ) => {
        try {
            const itemList = formData['livestockIds'].map((livestockId: string) => {
                const linkResolver = `${process.env.REACT_APP_DLR_API_URL}/nlisid/${livestockId}?linkType=gs1:certificationInfo`;
                return {
                    name: 'Cattle',
                    itemID: livestockId,
                    link: linkResolver,
                };
            });

            const eventTime = new Date().toUTCString();

            const senderData = splitStringByDash(formData['sender']);
            const receiverData = splitStringByDash(formData['receiver']);

            const credentialSubject = {
                sourceParty: { partyID: senderData[1], name: senderData[0] },
                destinationParty: { partyID: receiverData[1], name: receiverData[0] },
                transaction: { type: 'inv', identifier: formData['consignmentNumber'], documentURL: eNVDVCUrl },
                itemList: itemList,
                readPointId: generateUUID(),
                locationId: 'https://plus.codes/4RRHM6PR+MH', //Farm's Google Plus Code
                eventID: generateUUID(),
                eventTime: eventTime,
                eventType: EPCISEventType.Transaction,
                actionCode: EPCISEventAction.Observe,
                dispositionCode: EPCISEventDisposition.InTransit,
            };

            const restOfVC = { ...epcisTransactionCrendentialRender, type: ['TransactionEventCredential'] };
            const result: VerifiableCredential = await integrateVckitIssueVC({
                context: ['https://dpp-json-ld.s3.ap-southeast-2.amazonaws.com/transaction-event-ld.json'],
                credentialSubject,
                ...credentialPayload,
                restOfVC,
            });

            const credentialValue = result;
            return credentialValue;
        } catch (error) {
            throw new Error('Error issue credential');
        }
    };

    /**
     * upload VC to S3
     */
    const uploadVC = async (filename: string, vc: VerifiableCredential) => {
        const result = await uploadJson(filename, BucketName.PublicVC, vc);
        return result;
    };

    const encryptedVC = async (filename: string, vc: VerifiableCredential) => {
        const result = await encryptedService(filename, vc);
        return result;
    };

    const registerLinkResolver = async (
        url: string,
        identificationKeyType: IdentificationKeyType,
        identificationKey: string,
        title: string,
    ) => {
        const linkResolver: AgtraceLinkResolver = {
            identificationKeyType,
            identificationKey: identificationKey,
            itemDescription: `${defaultIssueFormValue[formName].title}`,
        };
        const query = encodeURIComponent(JSON.stringify({ payload: { uri: url } }));
        const queryString = `/?q=${query}`;
        const verificationPassportPage = `${process.env.REACT_APP_VERIFICATION_PAGE!}${queryString}`;
        const linkResponses: AgtraceLinkResponse[] = [
            {
                linkType: LinkType.epcisLinkType,
                linkTitle: title,
                targetUrl: url,
                mimeType: MimeType.applicationJson,
            },
            {
                linkType: LinkType.epcisLinkType,
                linkTitle: title,
                targetUrl: verificationPassportPage,
                mimeType: MimeType.textHtml,
                defaultLinkType: true,
                defaultIanaLanguage: true,
                defaultMimeType: true,
            },
        ];

        await createLinkResolver(linkResolver, linkResponses, queryString);
    };

    return (
        <>
            {dynamicSchema && <IssueFormWrap formName={formName} processor={processor} dynamicSchema={dynamicSchema} />}
        </>
    );
};
