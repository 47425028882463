import { GS1LinkResolver, IdentificationKeyType, privateAPI } from 'react-component-utils';
import { IdentificationKeyTypeCode } from '../models/gs1';

export const getLinkResolver = async (
    identificationKeyType: IdentificationKeyType,
    identificationKey: string,
): Promise<GS1LinkResolver[]> => {
    try {
        privateAPI.setBearerTokenAuthorizationHeaders(process.env.REACT_APP_DLR_API_KEY || '');
        const { data } = await privateAPI.get<GS1LinkResolver[]>(
            `${process.env.REACT_APP_DLR_API_URL}/resolver/${IdentificationKeyTypeCode[identificationKeyType]}/${identificationKey}`,
        );
        return data;
    } catch (error) {
        console.error(error);
        throw new Error('Error getting link resolver');
    }
};
